import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Icon,
  Input,
  InputElementProps,
  InputProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FieldError, Merge } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';

import { FormError } from '@cccom/shared/data-access';
import { i18n } from '@cccom/shared/i18n';

export type CCFormControlProps = InputProps & {
  label?: string;
  formControlProps?: FormControlProps;
  inputProps?: InputProps | InputElementProps;
  children?: ReactNode;
  error?:
    | FieldError
    | Merge<FieldError, (FieldError | undefined)[]>
    | FormError
    | null;
  isRequired?: boolean;
  showOptionalHint?: boolean;
  placeholder?: string;
  tooltipLabel?: ReactNode;
  'data-cy'?: string;
  fontFamily?: string;
  warningMessage?: string;
  formLabelProps?: FormLabelProps;
};

const optionalLabel = (
  <Text ml="2" as="span" color="gray.500">
    {i18n.t('forms.optional')}
  </Text>
);

/**
 * Wrapper around ChakraUI FormControl
 *
 * @see https://odysseyvc.atlassian.net/l/cp/DmXnP0jR
 */
export function CCFormControl({
  label,
  formControlProps,
  inputProps,
  children,
  error,
  isRequired = false,
  showOptionalHint = false,
  fontFamily,
  tooltipLabel,
  warningMessage,
  formLabelProps,
  ...rest
}: CCFormControlProps) {
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!(error === undefined || error === null)}
      data-cy={!inputProps ? rest['data-cy'] : null}
      {...formControlProps}
    >
      {label && (
        <>
          <FormLabel requiredIndicator={<span> *</span>} {...formLabelProps}>
            {label} {showOptionalHint ? optionalLabel : null}
          </FormLabel>
          {tooltipLabel && (
            <Tooltip
              hasArrow
              label={tooltipLabel}
              placement="right"
              shouldWrapChildren
              fontSize="xs"
              maxWidth="220px"
            >
              <Icon as={MdInfo} color="blackAlpha.400" />
            </Tooltip>
          )}
        </>
      )}

      {inputProps ? (
        <Input
          {...rest}
          {...inputProps}
          data-cy={rest['data-cy']}
          aria-label={`input-${label}`}
          autoComplete={rest.type === 'password' ? 'new-password' : 'off'}
        />
      ) : (
        <Box fontFamily={fontFamily || ''}>{children}</Box>
      )}

      {error && <FormErrorMessage>{error && error.message}</FormErrorMessage>}

      {warningMessage && (
        <FormHelperText color="red.500">{warningMessage}</FormHelperText>
      )}
    </FormControl>
  );
}
